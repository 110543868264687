@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-kanban/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";

/*@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-kanban/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";*/


:root {
    --color-precheck-background: '';
    --color-precheck-backgroundCell: '';
    --color-precheck-text: '';
    --color-adaptation-background: '';
    --color-adaptation-backgroundCell: '';
    --color-adaptation-text: '';
    --color-recording-background: '';
    --color-recording-backgroundCell: '';
    --color-recording-text: '';
    --color-postpro-background: '';
    --color-postpro-backgroundCell: '';
    --color-postpro-text: '';
    --color-qa-background: '';
    --color-qa-backgroundCell: '';
    --color-qa-text: '';
    --color-delivery-background: '';
    --color-delivery-backgroundCell: '';
    --color-delivery-text: '';
    --color-ai-background: '';
    --color-ai-backgroundCell: '';
    --color-ai-text: '';
    --selectedtask-background: '';
    --selectedtask-color: '';

    /* TC COLORS */
    --color-constraints-LS-background: '';
    --color-constraints-LS-text: '';
    --color-constraints-SS-background: '';
    --color-constraints-SS-text: '';
    --color-constraints-STC-background: '';
    --color-constraints-STC-text: '';
    --color-constraints-TC5-background: '';
    --color-constraints-TC5-text: '';
    --color-constraints-TC10-background: '';
    --color-constraints-TC10-text: '';
    --color-constraints-TC15-background: '';
    --color-constraints-TC15-text: '';
    --color-constraints-TC20-background: '';
    --color-constraints-TC20-text: '';
    --color-constraints-TC25-background: '';
    --color-constraints-TC25-text: '';
    --color-constraints-TC30-background: '';
    --color-constraints-TC30-text: '';
    --color-constraints-TC50-background: '';
    --color-constraints-TC50-text: '';
    --color-constraints-VO-background: '';
    --color-constraints-VO-text: '';
    --color-constraints-NLT-background: '';
    --color-constraints-NLT-text: '';

     /* TC COLORS BREAKDOWN */
     --colorBD-constraints-LS-background: '';
     --colorBD-constraints-LS-backgroundCell: '';
     --colorBD-constraints-LS-text: '';
     --colorBD-constraints-SS-background: '';
     --colorBD-constraints-SS-backgroundCell: '';
     --colorBD-constraints-SS-text: '';
     --colorBD-constraints-STC-background: '';
     --colorBD-constraints-STC-backgroundCell: '';
     --colorBD-constraints-STC-text: '';
     --colorBD-constraints-TC5-background: '';
     --colorBD-constraints-TC5-backgroundCell: '';
     --colorBD-constraints-TC5-text: '';
     --colorBD-constraints-TC10-background: '';
     --colorBD-constraints-TC10-backgroundCell: '';
     --colorBD-constraints-TC10-text: '';
     --colorBD-constraints-TC15-background: '';
     --colorBD-constraints-TC15-backgroundCell: '';
     --colorBD-constraints-TC15-text: '';
     --colorBD-constraints-TC20-background: '';
     --colorBD-constraints-TC20-backgroundCell: '';
     --colorBD-constraints-TC20-text: '';
     --colorBD-constraints-TC25-background: '';
     --colorBD-constraints-TC25-backgroundCell: '';
     --colorBD-constraints-TC25-text: '';
     --colorBD-constraints-TC30-background: '';
     --colorBD-constraints-TC30-backgroundCell: '';
     --colorBD-constraints-TC30-text: '';
     --colorBD-constraints-TC50-background: '';
     --colorBD-constraints-TC50-backgroundCell: '';
     --colorBD-constraints-TC50-text: '';
     --colorBD-constraints-VO-background: '';
     --colorBD-constraints-VO-backgroundCell: '';
     --colorBD-constraints-VO-text: '';
     --colorBD-constraints-NLT-background: '';
     --colorBD-constraints-NLT-backgroundCell: '';
     --colorBD-constraints-NLT-text: '';



    /* Heights of modules */
    --div-height-breakdown: 500px;
    --div-height-projectlist: 500px;
    --div-height-basicscript: 500px;
    --div-height-pronunciationspanel: 500px;
    --div-height-bdbuilder: 500px;
    --div-height-resourceapp: 500px;
    --div-height-resourceactors: 500px;
    --div-height-resourcegroups: 500px;
    --div-height-resourcerecstudios: 500px;
    --div-height-pronunciationglossaries: 500px;
    --div-height-scriptpronunciations: 500px;
    --div-height-scriptsidepanel: 500px;
    --div-height-ganttPlanner: 500px;
    --div-height-amsites: 500px;
    --div-height-amclients: 500px;
    --div-height-amlanguages: 500px;
    

    --div-height-rules: 500px;

    --zendesstatus: '0';

    --ssmarks-visibility: inline;
    --ssmarks-button-background: #0d6efd;
    --ssmarks-button-text: white;

    --script-highlightchanges-background-edit: white;
    --script-highlightchanges-background-noedit: none;
    --script-highlightchanges-button-background: white;
    --script-highlightchanges-button-text: #0d6efd;

    --showpronunciations-button-background: white;
    --showpronunciations-button-text: #0d6efd;
    --showpronunciations: 'none';

    --font-size-script: 13px;
    --font-height-script: 18px;

    --watermark-text: 'Watermark';
    --watermark-text-color: #bbbbbb;
    --watermark-text-opacity: 0.3;
    --watermark-text-size: 50px;

    --width-script-sidepanel: 400px;

}

/* menu bootstrap */

.sidebar {
    width: 60px;
    background-color: #282c34;
    color: #fff;
  }

  .sidebar.expanded{
    width: 230px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 0px 18px;
    cursor: pointer;
    border-radius: 3px;
    transition: width 0.3s;
  }

  .sidebar li a{
    color: #fff;
  }

  .sidebar li:hover{
    background: #0d6efd;
    color: #fff;
    width: 200px;
  }

  .sidebar li:active,
  .sidebar a.active li:active{
    background: #2c80a1;
    color: #fff;
    width: 210px;
  } 

  .sidebar li:hover span{
    width: auto;
  }

  .sidebar.expanded li:hover,
  .sidebar.expanded li:active{
    width: auto;
  }


  .sidebar li:hover a{
    color: #fff; 
  }  
  
  .sidebar i {
    font-size: 22px;
    color: #fff;
  }

  .sidebar:not(.expanded) li span {
    display: none;
    width: 200px;
    text-wrap: nowrap;
    /*animation: waitfinish 0s 0.3s forwards;
    visibility: hidden;*/
  }

  .sidebar:not(.expanded) li:hover span
  {
    display: inline-block;
  }

  @keyframes waitfinish {
    to { visibility: visible;}
  }

  .sidebar li:hover span {
    display: inline-block;
    width: 180px;
    visibility: visible;
    color: #fff;
    overflow: hidden;
    text-wrap: nowrap;
  }
  
  .sidebar li span {
    display: inline-block;
    color: #FFF;
    padding: 3px;
    width: 200px;
    text-wrap: nowrap;
  }

  .sidebar a.active li {
    background: #0d6efd;
  }

  .sidebar a.active i{
    color: #1A3763;
  }
  .sidebar a.active span{
    font-weight: bold;
  }


  /*@media (min-width: 1199px) {
    #main, #footer {
      margin-left: 0px;
    }
  }*/

/* Menu */


/*@media (min-width: 1200px) {
    #main, #footer {
        margin-left: 300px;
    }
}

@media (min-width: 1200px){
    .toggle-sidebar #main, .toggle-sidebar #footer {
        margin-left: 0;
    }
}*/

body > iframe {
    display: none;
  }

#AudioMateMenu{
    display:block;  
    width: 100%;
}

#AudioMateWindowMenu{
    position: absolute;
    top: 60px;
    left: 0px;
    background: #fff;
    z-index: 9
}

#AudioMateWindowMenu .menubox{
    position: relative;
    display: inline-block;
    text-align: center;
    width: 112px;
    height: 75px;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid #fff30d00;
    vertical-align: top;
}
#AudioMateWindowMenu.globalmenu a:hover{
    background: #f1f2ff;
    border: 1px solid #0d4eff;
    color: #ff8100
}


#AudioMateWindowMenu .menubox i{
    font-size: 28px;
}

#AudioMateWindowMenu .menubox div.menutext{
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
}

#AudioMateWindowMenu .menubox:hover{
    background: #f0f0f0;
    cursor: pointer;
}

.asimulatedlink{
    color: #4154f1;
    text-decoration: none;
    cursor: pointer; 
}

.asimulatedlink:hover{
    color: #4154f1;
    background-color: #f6f9ff;
}

/* navigation menu */

.dropdown-menu.dropdown-menu-end.dropdown-menu-arrow.menunav.show li{
    padding: 10px;
    width:300px;
}

.dropdown-menu.dropdown-menu-end.dropdown-menu-arrow.menunav.show .notification-item:hover{
    background:#6d93ff24;
    color: rgb(0, 38, 255);
    transition: 0.5s;
}

.menunav.show a{
    color: #000;
    font-size: 18px;
}

.notification-item i{
    font-size: 20px;
    margin-right: 15px;
}

.menunav.show li i{
    font-size: 20px;
    margin-left: 15px;
}

.dropdown-description{
    position: relative;
    text-align: center;
    font-size: 15px;
    padding: 5px 25px;
    font-weight: bold;
}

/* link not in blue */

/*#sidebar a.nav-link:not(.active){
    color: #000
}*/

/* project Navigation bar */

#ProjectsMatrix button{
    border: 1px solid #f5f3f3;
    width:120px;
}

#ProjectsMatrix button:hover{
    background: rgb(13,110,253);
    color: #fff;
}

.projectlist .e-rowcell.e-templatecell.removeWrap{
    white-space: pre-wrap; 
}

.projectlist .e-rowcell.e-templatecell.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis; 
}

.projectlist th.e-headercell.e-templatecell .e-checkbox-wrapper{
    display: none;
}

.projectlist .e-treegrid .e-treegridexpand::before,
#bdActors .e-treegrid .e-treegridexpand::before,
.e-treegrid .e-treegridexpand::before{
    content: "\e919";
    color: blue
}

.projectlist .e-treegrid .e-treegridcollapse::before,
#bdActors .e-treegrid .e-treegridcollapse::before{
    content: "\e848"
}

.projectlist th.e-headercell,
.projectlist th.e-headercell.e-lastcell,
#resourceapp th.e-headercell,
#resourceactors th.e-headercell,
#amsites th.e-headercell,
#amclients th.e-headercell,
#amlanguages th.e-headercell,
#resourcegroups th.e-headercell,
#resourcerecstudios th.e-headercell,
#rulesTablePlace th.e-headercell,
#shareListUsers tr.e-columnheader th.e-headercell,
.associationTables tr.e-columnheader th.e-headercell,
.associationTables th.e-rowdragheader,
.manageTarget th.e-headercell,
.AMQATags th.e-headercell,
.AMQATags th.e-rowdragheader{
    background: #222d65;
    color: #FFF;
    border: 1px solid #d7d7d7;  
}

div#amsites{
    height: var(--div-height-amsites);
}

div#amclients{
    height: var(--div-height-amclients);
}

div#amlanguages{
    height: var(--div-height-amlanguages);
}





div#amsites .disabled td,
div#amclients .disabled td,
div#amlanguages .disabled td{
    background:#ebebeb;
    color: #adadad;
}

.AMQATags th.e-rowdragheader{
    border-bottom: 1px;
}

#pronunciationglossaries th.e-headercell{
    background: #222d65;
    color: #FFF;
}

.e-grid th.e-headercell {
    height: 30px;
    padding: 6px;
}

.projectlist .e-grid .e-rowcell{
    padding: 2px 7px;
    height: 34px;
}


/*.projectlist .e-grid .e-treegridexpand.e-icons:not(.e-btn-icon)::before{
    color: #F00;
}

.projectlist .e-grid .e-treegridcollapse.e-icons:not(.e-btn-icon)::before{
    color: rgb(0, 119, 255);
}*/

/*.projectlist .e-treegrid .e-columnheader .e-icons.e-columnmenu::before {
    content: "\e901";
}*/

 /* breakdown style */

div.projectlist{
    height: var(--div-height-projectlist);
}

.e-treegrid .e-treecolumn-container span {
    display: inline;
    vertical-align: middle;
}

div.breakdown{
    margin-top: 1px;
    height: var(--div-height-breakdown);
}

div#scriptTable{
    height: var(--div-height-basicscript);
}

div#pronunciationsTable{
    position: relative;
    height: var(--div-height-pronunciationspanel);
}

div.containerTableBdBuilder{
    position: relative;
    height: var(--div-height-bdbuilder);
}

div#resourceapp{
    height: var(--div-height-resourceapp);
    padding-top:3px;
}

div#rulesTablePlace{
    height: var(--div-height-rules)
}

div#resourceactors{
    height: var(--div-height-resourceactors);
    padding-top:3px;
}

div#resourcegroups{
    height: var(--div-height-resourcegroups);
    padding-top:3px;
}

div#resourcerecstudios{
    height: var(--div-height-resourcerecstudios);
}

div#pronunciationglossaries{
    height: var(--div-height-pronunciationglossaries);
    padding-top:3px;
}

div#scriptpronunciations{
    height: var(--div-height-scriptpronunciations);
    padding-top:3px;
}

div#historymenu{
    height: var(--div-height-historymenu);
    padding-top:3px;
}

div#ganttPlanner{
    height: var(--div-height-ganttPlanner);
    padding-top:3px; 
}

div#pronunciationglossaries table.e-table tr.e-row td{
    padding: 0px 5px;
    position: relative;
}

.bre_akdown .e-columnheader th { /* grid content color*/ 
    background-color: #d9d9d9; 
    border-color: #bbbcbb; 
    color: black;    
}

.e-col_umnheader th{
    color: rgb(0, 0, 0); 
    font-weight: bold;
    font-size: 1.3em;
}

/* PROJECT TAB */

#listProjectTable .e-rowcell.e-templatecell.removemargins, 
#listProjectTable .e-columnheader th.e-headercell.removemargins.e-centeralign
{
    margin: 0px;
    padding: 0px;
}

button.bdfilter{
    width: 70px;
    height: 32px;
    font-size: 12px;
    text-wrap: nowrap;
    padding: 0px;
}

button.bdfilter.icon{
    width: 50px;
}

button.bdfilter.borderr{
    border-right: 2px solid #7777774d;
}

.breakdown td.e-summarycell{
    height: 30px;
}

.metricsTable .e-rowcell.keywords.metricsdesc{
    font-weight: bold;
    padding: 5px
}

.metricsTable .e-rowcell{
    padding: 7px
}

.TCLegend .e-rowcell,
.TCLegend .e-rowcell.e-templatecell.keywords{
    padding: 3px 7px;

}

.metricsTable div.taskBadge,
.TCLegend div.tcBadge{
    height: 15px;
    width: 7px;
    border-radius: 3px;
    margin: 1px 6px -2px 0px;
    display: inline-block;
}


.metricsTable div.taskBadge.precheck{
    background: var(--color-precheck-background);
}
.metricsTable div.taskBadge.adaptation{
    background: var(--color-adaptation-background);
}
.metricsTable div.taskBadge.recording{
    background: var(--color-recording-background);
}
.metricsTable div.taskBadge.postpro{
    background: var(--color-postpro-background);
}
.metricsTable div.taskBadge.qa{
    background: var(--color-qa-background);
}
.metricsTable div.taskBadge.ai{
    background: var(--color-ai-background);
}

.breakdown .e-headercell.keywords,
.breakdown td.e-summarycell.e-templatecell.keywords,
.breakdown td.e-summarycell.keywords,
button.bdfilter.btn.keywords.btn-sm.selected,
.metricsTable .e-columnheader .e-headercell.keywords,
.TCLegend .e-columnheader .e-headercell.keywords{
    background: #222d65;
    color:#FFF;
}

.dropdown-menu div.bdExpectedFiles{
    position: relative;
    width: 500px;
    padding: 6px 15px;
}

.dropdown-menu div.bdExpectedFiles table{
    margin-bottom: 0px;
    font-size: 12px;
}

.breakdown .e-rowcell.keywords.background{
    background: #fdfdfd;
    border-right: 1px solid #CCC;
    z-index: 1;
}

.breakdown .e-rowcell.keywords.background div.characterbox{
    background: #c6cef733;
    color: #000;
    padding: 3px 7px;
    border-radius: 4px;
    border: 1px solid #CCC;
    margin: 3px 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

button.bdfilter.btn.btn-outline-secondary.keywords.btn-sm:not(.selected):hover{
    background: #002aff21;
    color: #222d65;
}

/*breakdown .e-grid .e-gridheader .e-sortfilter .e-headercelldiv .e-headertext {
    margin:0px;
}   */

.breakdown th.e-headercell.e-fltr-icon.e-focus.keywords .e-headercelldiv .e-headertext{
/*.breakdown th.e-headercell.e-focus .e-headercelldiv .e-headertext{*/
    color:#FFF;
}

.breakdown .e-grid th.VO.e-headercell[aria-sort=ascending] .e-headertext,
.breakdown .e-grid th.VO.e-headercell[aria-sort=descending] .e-headertext,
.breakdown .e-grid th.post.e-headercell[aria-sort=ascending] .e-headertext,
.breakdown .e-grid th.post.e-headercell[aria-sort=descending] .e-headertext{
    color:#000;
}

.breakdown .e-grid th.e-headercell[aria-sort=ascending] .e-headertext,
.breakdown .e-grid th.e-headercell[aria-sort=descending] .e-headertext,
.breakdown .e-grid th.STC.e-headercell[aria-sort=ascending] .e-headertext,
.breakdown .e-grid th.STC.e-headercell[aria-sort=descending] .e-headertext{
    color:#FFF;
}



/* filter rec icon */
/*e-filtermenudiv e-icons e-icon-filter e-filtered*/
.breakdown .e-grid th.rec .e-filtered::before {
    color:#16e360
}


.breakdown .e-grid.e-default .e-headercell, .breakdown.e-grid.e-default .e-detailheadercell {
    padding: 0px 9px;
    margin: 0px;
}

.breakdown .e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop):not(.e-icon-reorderuparrow):not(.e-icon-reorderdownarrow){
    color: #FFF;
}

/* headers text no ellipsis */
.breakdown table .e-grid.e-responsive .e-rowcell,
.breakdown table .e-grid.e-responsive .e-headercelldiv{
    text-overflow: initial;
}

.breakdown table .e-rowcell{
    padding: 0 15px;
}

.breakdown .e-rowcell.keywords{
    background: #222d6505;
    color: #000;
}

.breakdown .e-rowcell.borderLeft{
    border-left: 2px solid #000;
}

/* precheck */
.breakdown .e-headercell.precheck,
.breakdown td.e-summarycell.precheck,
button.bdfilter.btn.precheck.btn-sm.selected{
    background: var(--color-precheck-background);
    color: var(--color-precheck-text);
}

.breakdown tr.e-summaryrow td.e-summarycell{
    max-height: 40px; /* Imposta l'altezza massima a 20px */
    overflow: hidden; /* Nasconde il contenuto che eccede l'elemento */
    white-space: nowrap; /* Impedisce al testo di andare a capo */
    text-overflow: ellipsis; /* Aggiunge ellissi se il testo eccede la larghezza disponibile */
  }


button.bdfilter.btn.btn-outline-secondary.precheck.btn-sm:not(.selected):hover{
    background: var(--color-precheck-backgroundCell);
    color: var(--color-precheck-background);;
}

.breakdown .e-rowcell.precheck{
    background: var(--color-precheck-backgroundCell);
}


.breakdown .e-grid .e-gridheader .e-stackedheadercelldiv,
.breakdown .e-grid .e-gridheader .e-sortfilter .e-headercelldiv{
    text-overflow: clip;
}

.breakdown .e-headercell.adapt,
.breakdown td.e-summarycell.adapt,
button.bdfilter.btn.adapt.btn-sm.selected{
    background: var(--color-adaptation-background);
    color: var(--color-adaptation-text);
}

button.bdfilter.btn.btn-outline-secondary.adapt.btn-sm:not(.selected):hover{
    background: var(--color-adaptation-backgroundCell);
    color: var(--color-adaptation-background);;
}


.breakdown .e-rowcell.adapt{
    background: var(--color-adaptation-backgroundCell);
}

.breakdown .e-headercell.rec,
.breakdown td.e-summarycell.rec,
button.bdfilter.btn.rec.btn-sm.selected{
    background: var(--color-recording-background);
    color: var(--color-recording-text);
}

button.bdfilter.btn.btn-outline-secondary.rec.btn-sm:not(.selected):hover{
    background: var(--color-recording-backgroundCell);
    color: var(--color-recording-background);;
}

.breakdown .e-rowcell.rec{
    background: var(--color-recording-backgroundCell);
}

.breakdown .e-headercell.post,
.breakdown td.e-summarycell.post,
button.bdfilter.btn.post.btn-sm.selected{
    background: var(--color-postpro-background);
    color: var(--color-postpro-text);
}

button.bdfilter.btn.btn-outline-secondary.post.btn-sm:not(.selected):hover{
    background: var(--color-postpro-backgroundCell);
    color: var(--color-postpro-background);;
}

.breakdown .e-rowcell.post{
    background: var(--color-postpro-backgroundCell);
}



.breakdown .e-headercell.qa,
.breakdown td.e-summarycell.qa,
button.bdfilter.btn.qa.btn-sm.selected{
    background: var(--color-qa-background);
    color: var(--color-qa-text);
}

button.bdfilter.btn.btn-outline-secondary.qa.btn-sm:not(.selected):hover{
    background: var(--color-qa-backgroundCell);
    color: var(--color-qa-background);;
}

.breakdown .e-rowcell.qa{
    background: var(--color-qa-backgroundCell);
}

.breakdown .e-headercell.delivery,
.breakdown td.e-summarycell.delivery,
button.bdfilter.btn.delivery.btn-sm.selected{
    background: var(--color-delivery-background);
    color: var(--color-delivery-text);
}

button.bdfilter.btn.btn-outline-secondary.delivery.btn-sm:not(.selected):hover{
    background: var(--color-delivery-backgroundCell);
    color: var(--color-delivery-background);
}

.breakdown .e-rowcell.delivery{
    background: var(--color-delivery-backgroundCell);
}

.breakdown .e-headercell.ai,
.breakdown td.e-summarycell.ai,
button.bdfilter.btn.ai.btn-sm.selected{
    background: var(--color-ai-background);
    color: var(--color-ai-text);
}

button.bdfilter.btn.btn-outline-secondary.ai.btn-sm:not(.selected):hover{
    background: var(--color-ai-backgroundCell);
    color: var(--color-ai-background);;
}

.breakdown .e-rowcell.ai{
    background: var(--color-ai-backgroundCell);
}

/* selection */
.breakdown .e-rowcell.e-cellselectionbackground.keywords,
.breakdown .e-rowcell.e-cellselectionbackground.precheck,
.breakdown .e-rowcell.e-cellselectionbackground.adapt,
.breakdown .e-rowcell.e-cellselectionbackground.rec,
.breakdown .e-rowcell.e-cellselectionbackground.post,
.breakdown .e-rowcell.e-cellselectionbackground.qa,
.breakdown .e-rowcell.e-cellselectionbackground.ai,
.breakdown .e-rowcell.LS.e-cellselectionbackground,
.breakdown .e-rowcell.NLT.e-cellselectionbackground,
.breakdown .e-rowcell.SS.e-cellselectionbackground,
.breakdown .e-rowcell.STC.e-cellselectionbackground,
.breakdown .e-rowcell.TC5.e-cellselectionbackground,
.breakdown .e-rowcell.TC10.e-cellselectionbackground,
.breakdown .e-rowcell.TC15.e-cellselectionbackground,
.breakdown .e-rowcell.TC20.e-cellselectionbackground,
.breakdown .e-rowcell.TC25.e-cellselectionbackground,
.breakdown .e-rowcell.TC30.e-cellselectionbackground,
.breakdown .e-rowcell.TC50.e-cellselectionbackground,
.breakdown .e-rowcell.VO.e-cellselectionbackground{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: dashed #f00;
    border-width: 2px 2px 2px 2px;
}

.breakdown .e-rowcell.e-cellselectionbackground.keywords:last-child,
.breakdown .e-rowcell.LS.e-cellselectionbackground:last-child{
    border-width: 10px 10px 10px 10px;
}

/* BD constraints */

.breakdown .e-grid .e-gridcontent.e-wrap .e-rowcell{
    height: 40px;
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.LS,
.breakdown .e-summarycell.e-templatecell.LS,
.metricsTable .e-columnheader .e-headercell.LS,
.tcBadge.LS{
    background: var(--colorBD-constraints-LS-background);
    color: var(--colorBD-constraints-LS-text);
}

.breakdown .e-rowcell.LS{
    background: var(--colorBD-constraints-LS-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.SS,
.breakdown .e-summarycell.e-templatecell.SS,
.metricsTable .e-columnheader .e-headercell.SS,
.tcBadge.SS{
    background: var(--colorBD-constraints-SS-background);
    color: var(--colorBD-constraints-SS-text);
}

.breakdown .e-rowcell.SS{
    background: var(--colorBD-constraints-SS-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.STC,
.breakdown .e-summarycell.e-templatecell.STC,
.metricsTable .e-columnheader .e-headercell.STC,
.tcBadge.STC{
    background: var(--colorBD-constraints-STC-background);
    color: var(--colorBD-constraints-STC-text);
}

.breakdown .e-rowcell.STC{
    background: var(--colorBD-constraints-STC-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.TC5,
.breakdown .e-summarycell.e-templatecell.TC5,
.metricsTable .e-columnheader .e-headercell.TC5,
.tcBadge.TC5{
    background: var(--colorBD-constraints-TC5-background);
    color: var(--colorBD-constraints-TC5-text);
}

.breakdown .e-rowcell.TC5{
    background: var(--colorBD-constraints-TC5-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.TC10,
.breakdown .e-summarycell.e-templatecell.TC10,
.metricsTable .e-columnheader .e-headercell.TC10,
.tcBadge.TC10{
    background: var(--colorBD-constraints-TC10-background);
    color: var(--colorBD-constraints-TC10-text);
}

.breakdown .e-rowcell.TC10{
    background: var(--colorBD-constraints-TC10-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.TC15,
.breakdown .e-summarycell.e-templatecell.TC15,
.metricsTable .e-columnheader .e-headercell.TC15,
.tcBadge.TC15{
    background: var(--colorBD-constraints-TC15-background);
    color: var(--colorBD-constraints-TC15-text);
}

.breakdown .e-rowcell.TC15{
    background: var(--colorBD-constraints-TC15-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.TC20,
.breakdown .e-summarycell.e-templatecell.TC20,
.metricsTable .e-columnheader .e-headercell.TC20,
.tcBadge.TC20{
    background: var(--colorBD-constraints-TC20-background);
    color: var(--colorBD-constraints-TC20-text);
}

.breakdown .e-rowcell.TC20{
    background: var(--colorBD-constraints-TC20-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.TC25,
.breakdown .e-summarycell.e-templatecell.TC25,
.metricsTable .e-columnheader .e-headercell.TC25,
.tcBadge.TC25{
    background: var(--colorBD-constraints-TC25-background);
    color: var(--colorBD-constraints-TC25-text);
}

.breakdown .e-rowcell.TC25{
    background: var(--colorBD-constraints-TC25-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.TC30,
.breakdown .e-summarycell.e-templatecell.TC30,
.metricsTable .e-columnheader .e-headercell.TC30,
.tcBadge.TC30{
    background: var(--colorBD-constraints-TC30-background);
    color: var(--colorBD-constraints-TC30-text);
}

.breakdown .e-rowcell.TC30{
    background: var(--colorBD-constraints-TC30-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.TC50,
.breakdown .e-summarycell.e-templatecell.TC50,
.metricsTable .e-columnheader .e-headercell.TC50,
.tcBadge.TC50{
    background: var(--colorBD-constraints-TC50-background);
    color: var(--colorBD-constraints-TC50-text);
}

.breakdown .e-rowcell.TC50{
    background: var(--colorBD-constraints-TC50-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.VO,
.breakdown .e-summarycell.e-templatecell.VO,
.metricsTable .e-columnheader .e-headercell.VO,
.tcBadge.VO{
    background: var(--colorBD-constraints-VO-background);
    color: var(--colorBD-constraints-VO-text);
}

.breakdown .e-rowcell.VO{
    background: var(--colorBD-constraints-VO-backgroundCell);
}

.breakdown .e-grid.e-default.e-bothlines .e-headercell.NLT,
.breakdown .e-summarycell.e-templatecell.NLT,
.metricsTable .e-columnheader .e-headercell.NLT,
.tcBadge.NLT{
    background: var(--colorBD-constraints-NLT-background);
    color: var(--colorBD-constraints-NLT-text);
}

.breakdown .e-rowcell.NLT{
    background: var(--colorBD-constraints-NLT-backgroundCell);
}


.breakdown .progressbar__progress,.progressbar__progress{
    position: relative;
    align-self: center;
    height: 18px;
    border-radius: 10px;
    outline-style: solid;
    outline-width: 1px;
    outline-color: #1d1d1d2e;
    overflow: hidden;
}

.breakdown div.deliverytime{
    position: relative;
    background: #fffb669c;
    margin: 2px 0px;
    padding: 4px 7px;
    border-radius: 5px;
    font-size: 11px;
    text-align: center;
    border: 1px solid #CCC;
}


.breakdown .progressbar__back, .progressbar__back{
    position: absolute;
    /*z-index: 0;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #0d6efd;
    color: white;
}

.breakdown .progressbar__back.completed{
    background: #198754;
}

.breakdown .progressbar__front.notStarted{
    color: #a3a3a3
}

.breakdown .progressbar__front, .progressbar__front {
    position: absolute;
    /*z-index: 1;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: whitesmoke;
    color: black; 
    border: 1px solid whitesmoke;
}

/* booking windows */

.e-row.outofwindowRange td,
.e-row.outofwindowRange td .e-btn.e-flat,
.e-row.outofwindowRange td:hover,
.e-row.outofwindowRange td:hover .e-btn.e-flat,
.breakdown div.deliverytime.outside{
    background: #F00 !important;
    color: #FFF !important;
}

/* BD Builder */
.containerTableBdBuilder div.e-gridheader,
.containerTableBdBuilder .e-grid .e-gridheader tr th:first-child,
.containerTableBdBuilder tr.e-columnheader th.e-headercell,
#GridAct div.e-gridheader,
#GridAct tr.e-columnheader th.e-headercell{
    background: #222d65;
    color: #FFF;
    font-weight: bold;
    /*border: 1px solid #d7d7d7;*/
}

.containerTableBdBuilder.search div.e-gridheader,
.containerTableBdBuilder.search .e-grid .e-gridheader tr th:first-child,
.containerTableBdBuilder.search tr.e-columnheader th.e-headercell{
    background: #ff5b5b;
    /*border: 1px solid #d7d7d7;*/
}

.containerTableBdBuilder .e-grid .e-rowcell,
.containerTableBdBuilder .e-row .e-rowcell.e-treerowcell{
    padding: 4px 8px;
    border-color:#e7e7e7a8;
}

.containerTableBdBuilder .btn.btn-sm.bdbuilder-play-button,
#pronunciationsTable .btn.btn-sm.pronunciations-play-button,
.btn.btn-sm.pronunciations-play-button{
    padding: 1px 3px;
    color: #4154f1;
}

.containerTableBdBuilder .btn.btn-sm.bdbuilder-play-button:hover,
#pronunciationsTable .btn.btn-sm.pronunciations-play-button:hover,
.btn.btn-sm.pronunciations-play-button:hover{
    background: #4154f1;
    color: #fff;  
}

/* drag icon to the left table */
.containerTableBdBuilder #Grid tr.e-row{
    cursor: grab;
}

button.lineuploadIcon{
    background: none;
    border: 2px dashed #4154f1;
    padding: 6px;
    top: -4px;
}

button.lineuploadIcon:hover{
    background: #4154f1;
    border: 2px dashed #FFF;

}

.containerTableBdBuilder .btn.btn-sm.bdbuilder-play-button:active,
#pronunciationsTable .btn.btn-sm.pronunciations-play-button:active,
.btn.btn-sm.pronunciations-play-button:active{
    background: #2835a3;
    color: #fff;  
}

.containerTableBdBuilder .btn:disabled.btn-sm.bdbuilder-play-button,
#pronunciationsTable .btn:disabled.btn-sm.pronunciations-play-button,
.btn:disabled.btn-sm.pronunciations-play-button{
    background: none;
    color: #4154f1;
    opacity: 0.3;
    border-width: 0px
}

.containerTableBdBuilder tr.avoiddrag td:first-child div{
    display: none
}



/*.breakdown .e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv {
    padding: 0 0em 0 0.6em;
}

.breakdown .e-grid .e-rowcell{
    padding:5px 15px
}

.breakdown .e-columnheader th span.e-headertext{
    font-size: 0.8em
}

.breakdown .e-row td{
    font-size: 0.9em
}*/

/* button to add */
button.nobuttonLink{
    border: 0px;
    background: none;
    padding: 0px;
    font-size: 18px;
}

/* roles in Studio management */

.badge.resource{
    margin:3px;
}

.StudiosColorpicker{
    position: relative;
    width:100%
}

.StudiosColorpicker div{
    position: relative;
    width: 18px;
    height: 18px;
    margin-left: 87px;
}


/* TEMP FOR SCHEDULER */
.drag-sample-wrapper {
    display: -ms-flexbox;
    display: flex;
}

.schedule-container {
    padding-right: 10px;
    width: 100%;
}

.title-container {
    padding-bottom: 10px;
}

.treeview-external-drag #waiting {
    height: 100%;
    padding: 0;
}

.treeview-external-drag #waitdetails {
    width: 95%;
    float: left;
    height: 100%;
    padding: 0;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .template-wrap {
    padding: 3px 0px;
}

.title-text {
    font-size: 18px;
    margin: 0px;
    font-weight: bold;
    text-align: center;
}


.treeview-external-drag #waitlist {
    width: 100%;
    height: 37%;
    font-weight: bold;
    font-family: "Segoe UI";
    font-size: 12px;
    padding: 5px 0 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.treeview-external-drag #waitcategory {
    height: 50%;
    font-family: "Segoe UI";
    font-size: 10px;
    opacity: 0.6;
    padding-left: 10px;
    padding-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.treeview-external-drag .e-list-text, .treeview-external-drag.e-rtl .e-list-text, .e-bigger .treeview-external-drag .e-list-text, .e-bigger .treeview-external-drag.e-rtl .e-list-text {
    border: 0.5px solid #E1E7EC;
    height: 73px;
    line-height: 15px;
    padding: 0 5px;
    width: 220px;
}

.booktime{
    color: firebrick;
    font-size: 12px;
    font-weight: bold;
}

.treeview-external-drag .e-list-parent, .treeview-external-drag.e-rtl .e-list-parent, .e-bigger .treeview-external-drag .e-list-parent, .e-bigger .treeview-external-drag.e-rtl .e-list-parent {
    height: 100%;
    padding: 0 2px;
}

.treeview-external-drag .e-list-item, .e-bigger .treeview-external-drag .e-list-item {
    height: 100%;
    padding: 0 0 5px 0;
}

.treeview-external-drag .e-fullrow, .e-bigger .treeview-external-drag .e-fullrow {
    height: 55px;
}

.treeview-external-drag .e-list-item.e-hover>.e-fullrow, .treeview-external-drag .e-list-item.e-active>.e-fullrow, .treeview-external-drag .e-list-item.e-active.e-hover>.e-fullrow, .e-bigger .treeview-external-drag .e-list-item.e-hover>.e-fullrow, .e-bigger .treeview-external-drag .e-list-item.e-active>.e-fullrow, .e-bigger .treeview-external-drag .e-list-item.e-active.e-hover>.e-fullrow {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
}

.treeview-external-drag .e-text-content, .e-bigger .treeview-external-drag .e-text-content, .treeview-external-drag.e-rtl .e-text-content, .e-bigger .treeview-external-drag.e-rtl .e-text-content {
    padding: 0;
    background-color: inherit;
}

.e-drag-item.e-treeview.treeview-external-drag, .e-bigger .e-drag-item.e-treeview.treeview-external-drag {
    padding: 0 !important;
}

.e-schedule.schedule-drag-drop .e-timeline-view .e-resource-left-td, .e-schedule.schedule-drag-drop .e-timeline-month-view .e-resource-left-td {
    width: 160px;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-category {
    padding-left: 30px
}

.e-schedule.e-rtl.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-category {
    padding-right: 30px
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-category, .e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-name {
    padding: 5px
}

.tailwind .e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-category, .tailwind .e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-name, .tailwind-dark .e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-category, .tailwind-dark .e-schedule.schedule-drag-drop .e-resource-cells.e-child-node .specialist-name {
    padding: unset !important;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-name {
    padding: 0 10px
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-name {
    font-size: 13px;
}

.e-schedule.schedule-drag-drop .e-resource-cells .specialist-designation {
    font-size: 10px;
}

.e-schedule.schedule-drag-drop .specialist-image {
    width: 45px;
    height: 40px;
    float: left;
    border-radius: 50%;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 45px;
}

.e-device-hover {
    background-color: #e0e0e0 !important;
}

.e-schedule.schedule-drag-drop .e-resource-cells.e-parent-node .specialist-image {
    display: none;
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.alice {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/alice.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.robert {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robert.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.robson {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robson.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.laura {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/laura.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.nancy {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/nancy.png');
}

.schedule-drag-drop.e-schedule .template-wrap .specialist-image.margaret {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/margaret.png');
}

@media (max-width: 550px) {
    .drag-sample-wrapper {
        display: block;
    }
    .schedule-container {
        padding-bottom: 10px
    }
    .treeview-external-drag.e-treeview, .e-bigger .treeview-external-drag.e-treeview {
        width: 250px;
    }
    .e-bigger .treeview-external-drag.e-treeview.e-drag-item {
        position: relative !important;
    }
}
.e-disble-not-allowed {
   cursor: unset !important;
}

.e-drag-item.treeview-external-drag .e-icon-expandable {
   display: none;
}

/* END TEMP FOR SCHEDULER */


/* GANT CHART PAGE */

.ganttWrapper {
    width: 85%;
    float: left;
}

.ganttWrapper .e-connectorpoint-right.e-connectorpoint-right-hover,
.ganttWrapper .e-connectorpoint-left.e-connectorpoint-left-hover{
    display:none;
}

.ganttWrapper .e-gantt .e-gantt-chart .e-chart-root-container .e-content{
    overflow: scroll;
    overflow-y: hidden;
}

.gant-draggable-users{
    height: 600px;
    position: relative;
    overflow: overlay;
    width:15%;
}

.gant-draggable-users .e-text-content{
    padding:0px;
}

.gant-draggable-users{
    /*opacity:0;*/
    background: #fff8f3;
}

.gant-draggable-users-header{
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-width: 1px 1px 1px 0px;
    height: 44px;
    padding: 10px;
    background: #fafafa;
}

/* Assign BD */

.ActorCont{
    height: 600px;
    position: relative;
    overflow: auto;
}

/*.ActorCont .e-grid:not(.e-rowcell){
    font-weight: bold
}

.ActorCont .e-row.e-rowcell td{
    color: #F00
}*/

/* audio player */

#player-filename{
    color: #FFF;
    font-size: 11px;
    padding-left: 15px;
    border-color: #0d6efd78;
    display: none;
}




.scriptplayer{
    position: relative;
    cursor: pointer;
    font-size: 24px;
}

.scriptplayer:hover{
    padding: 0px;
}

/* buttons script */

.btn.playAudio{
    height: 28px;
    width: 28px;
    padding: 0px;
    margin: 2px;
}

.btn.playAudio.playalt{
    padding: 3px 6px 1px;
    margin: 2px;
}

.btn.playAudio.noaudio{
    opacity:0.3;
}

.btn.statusupdate{
    height: 28px;
    width: 28px;
    padding: 0;
    margin: 2px;
}

div.scriptTaskCompleted{
    display: inline-block;
    width: 14px;
    height: 14px;    
    border-radius: 30px;
    margin-right: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(160, 160, 160);
}

.scriptTaskCompletedDiv{
    margin-top: 4px;
}

/* SCRIPTS TABLE */

/* font size icons in headers*/
#scriptTable .e-filtermenudiv{
    position: relative;
    font-size: 12px;
    top: -2px;
}

/* full screen mode */
#scriptcontainer.scriptfullscreen{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    width: 100%;
    border-radius: 0px;
    background: #FFF;
}

#scriptcontainer.scriptfullscreen .pagetitle h1,
#scriptcontainer.scriptfullscreen .nav.nav-tabs{
    display:none;
}

#scriptcontainer.scriptfullscreen nav{
    margin: 9px 0px 6px 46px;
}

#scriptcontainer.scriptfullscreen #divcard.card{
    position: fixed;
    top: 36px;
    width: 100%;
}

#audioMateLogoScript{
    width: 25px;
    height: 25px;
    display: none;
}

#scriptcontainer.scriptfullscreen #audioMateLogoScript{
    position: absolute;
    display: inline-block;
    margin: 6px 15px
}

/* Colors of headers */
#scriptTable .e-headercell.precheck,
#scriptTable .e-headercell.precheckeditable,
button.btn.statusupdate.precheck {
    border-color: var(--color-precheck-background);
    background: var(--color-precheck-background);
    color:var(--color-precheck-text); 
}
button.btn.statusupdate.precheck.incompleted {
    background: none;
    color:var(--color-precheck-background); 
}

/* Colors of headers */
#scriptTable .e-rowcell.precheckeditable,
#scriptTable .e-rowcell.precheckeditable.e-updatedtd,
#scriptTable .e-rowcell.adaptationeditable,
#scriptTable .e-rowcell.adaptationeditable.e-updatedtd,
#scriptTable .e-rowcell.recordingeditable,
#scriptTable .e-rowcell.recordingeditable.e-updatedtd,
#scriptTable .e-rowcell.postproeditable,
#scriptTable .e-rowcell.postproeditable.e-updatedtd,
#scriptTable .e-rowcell.qaeditable,
#scriptTable .e-rowcell.qaeditable.e-updatedtd,
#scriptTable .e-rowcell.aieditable,
#scriptTable .e-rowcell.aieditable.e-updatedtd,
#scriptTable .e-rowcell.deliveryeditable,
#scriptTable .e-rowcell.deliveryeditable.e-updatedtd{
    background: #FFF;
}

.showpronunciations table.e-table tr.e-row td.e-rowcell{
    padding: 2px;
}

#scriptTable .e-grid .e-rowcell {
    padding: 4px 6px;
    white-space: pre-wrap;
    font-size: var(--font-size-script);
    line-height: var(--font-height-script);
    position: relative;
}

button.btn.statusupdate {
    border-width: 2px;
}

#scriptTable .e-headercell.adaptation,
#scriptTable .e-headercell.adaptationeditable,
button.btn.statusupdate.adaptation {
    border-color: var(--color-adaptation-background);
    background: var(--color-adaptation-background);
    color:var(--color-adaptation-text); 
}
button.btn.statusupdate.adaptation.incompleted {
    background: none;
    color:var(--color-adaptation-background); 
}

#scriptTable .e-headercell.recording,
#scriptTable .e-headercell.recordingeditable,
button.btn.statusupdate.recording {
    border-color: var(--color-recording-background);
    background: var(--color-recording-background);
    color:var(--color-recording-text); 
}
button.btn.statusupdate.recording.incompleted {
    background: none;
    color:var(--color-recording-background); 
}

#scriptTable .e-headercell.postpro,
#scriptTable .e-headercell.postproeditable,
button.btn.statusupdate.postpro {
    border-color: var(--color-postpro-background);
    background: var(--color-postpro-background);
    color:var(--color-postpro-text); 
}
button.btn.statusupdate.postpro.incompleted {
    background: none;
    color:var(--color-postpro-background); 
}

#scriptTable .e-headercell.qa,
#scriptTable .e-headercell.qaeditable,
button.btn.statusupdate.qa {
    border-color: var(--color-qa-background);
    background: var(--color-qa-background);
    color:var(--color-qa-text); 
}
button.btn.statusupdate.qa.incompleted {
    background: none;
    color:var(--color-qa-background); 
}

#scriptTable .e-headercell.delivery,
#scriptTable .e-headercell.deliveryeditable,
button.btn.statusupdate.delivery {
    border-color: var(--color-delivery-background);
    background: var(--color-delivery-background);
    color:var(--color-delivery-text); 
}
button.btn.statusupdate.delivery.incompleted {
    background: none;
    color:var(--color-delivery-background); 
}

#scriptTable .e-headercell.ai,
#scriptTable .e-headercell.aieditable,
button.btn.statusupdate.ai {
    border-color: var(--color-ai-background);
    background: var(--color-ai-background);
    color:var(--color-ai-text); 
}
button.btn.statusupdate.ai.incompleted {
    background: none;
    color:var(--color-ai-background); 
}

div.scriptTaskCompleted.pre{
    background: var(--color-precheck-background);
    /*border-color: var(--color-precheck-background);*/
}
div.scriptTaskCompleted.adapt{
    background: var(--color-adaptation-background);
    /*border-color: var(--color-adaptation-background);*/
}
div.scriptTaskCompleted.recorded{
    background: var(--color-recording-background);
    /*border-color: var(--color-recording-background);*/
}
div.scriptTaskCompleted.post{
    background: var(--color-postpro-background);
    /*border-color: var(--color-postpro-background);*/
}
div.scriptTaskCompleted.qa{
    background: var(--color-qa-background);
    /*border-color: var(--color-qa-background);*/
}
div.scriptTaskCompleted.del{
    background: var(--color-delivery-background);
    /*border-color: var(--color-delivery-background);*/
}
div.scriptTaskCompleted.ai{
    background: var(--color-ai-background);
    /*border-color: var(--color-recording-background);*/
}

div.scriptTaskCompleted.pre.notcompleted, 
div.scriptTaskCompleted.adapt.notcompleted, 
div.scriptTaskCompleted.recorded.notcompleted, 
div.scriptTaskCompleted.post.notcompleted, 
div.scriptTaskCompleted.qa.notcompleted, 
div.scriptTaskCompleted.del.notcompleted,
div.scriptTaskCompleted.ai.notcompleted {
    display: none;
    background: rgb(230, 230, 230);
}

div.scriptTaskCompleted.adapt.notneeded {
    opacity: 0.3;
}

#scriptTable .e-grid td.e-active{
    /*background: #001dfb45;*/
    /* border-color: #000; */
    color: black;
}


/* (Dani): Beginning of styles added by Dani */
#scriptTable .e-grid.e-default.e-bothlines .e-headercell, 
#scriptTable .e-grid.e-default.e-bothlines .e-rowcell {
    border-color: rgb(192, 192, 192);
}
.e-grid .e-gridheader tr th:first-child {
    padding: 6px;
}

/*.e-grid th.e-headercell {
    height: 30px;
    padding: 6px;
}*/

#targetsettingsgrid .cellediting {
    outline-color: #be0000;
    outline-style: solid;
    outline-offset: -2px;
    outline-width: 2px;
}

#scriptTable .cellediting {
    outline-color: #be0000;
    outline-style: solid;
    outline-offset: -4px;
    outline-width: 4px;
}

#scriptTable .activated--edit {
    padding: 8px !important;
    padding-bottom: 8px !important;
}

#scriptTable .e-grid td.e-selectionbackground.e-rowcell.e-rowcell {
    font-weight: bold;
    border-top-color: rgb(190, 0, 0);
    border-bottom-color: rgb(190, 0, 0);
    border-top-width: 2px;
    border-bottom-width: 2px;
}

#scriptTable .e-rowcell.precheck,
#scriptTable .e-rowcell.adaptation,
#scriptTable .e-rowcell.recording,
#scriptTable .e-rowcell.postpro,
#scriptTable .e-rowcell.qa,
#scriptTable .e-rowcell.delivery,
#scriptTable .e-rowcell.ai{
    background: transparent;
 }
 #scriptTable .noeditablenotcomplete {
    background: whitesmoke; 
  }
 #scriptTable .noeditableprecheckcomplete {
    background: #00000020; 
  }
  #scriptTable .noeditableadaptationcomplete {
    background: #ED7D3130; 
  }
  #scriptTable .noeditablerecordingcomplete {
    background: #DA000025; 
  }
  #scriptTable .noeditablepostprocomplete {
    background: #ffd60040; 
  }
  #scriptTable .noeditableqacomplete {
    background: #0070C025; 
  }
  #scriptTable .noeditabledeliverycomplete {
    background: #20831B25; 
  }
  #scriptTable .noeditableaicomplete {
    background: #c3009b25; 
  }

/* highlight text changes */
#scriptTable .e-rowcell.highlightchangesedit {
    background: var(--script-highlightchanges-background-edit);
}
#scriptTable .e-rowcell.highlightchangesnoedit {
    background: var(--script-highlightchanges-background-noedit);
}

/* filter characters no selected */
#scriptTable .unsetcharacter {
    background: #0c0c0c30;
}

#scriptTable .e-sortfilterdiv.e-icons {
    display: none;
}

#scriptTable .e-grid .e-icon-filter.e-filtered::before {
    color: white;
}

#scriptTable .nottoadapt {
    background: #00000050;
    opacity: 0.8;
}
#scriptTable .e-headercelldiv{
    font-size: var(--font-size-script);
}

/* pronunciation panel */
#pronunciationsTable table.e-table thead tr th{
    background: #d7d7d7;
}

#pronunciationsTable table.e-table thead tr th.e-headercell div.e-headercelldiv span.e-headertext{
    font-weight: bold;
    font-size: 1.1em;
}
#pronunciationglossaries .e-grid .e-icon-filter {
    color: rgba(255, 255, 255, 0.514);
}
#pronunciationglossaries .e-grid .e-icon-filter.e-filtered::before {
    color: rgb(223, 0, 0);
}

.counter.precheck {
    display: flex;
    border-style: solid;
    border-width: 0px;
    border-color: gray;
    border-radius: 4px;
    margin: 0px 4px;
    padding: 1px;
    background: var(--color-precheck-background);
    color:var(--color-precheck-text); 
}
.counter.adaptation {
    display: flex;
    border-style: solid;
    border-width: 0px;
    border-color: gray;
    border-radius: 4px;
    margin: 0px 4px;
    padding: 1px;
    background: var(--color-adaptation-background);
    color:var(--color-adaptation-text); 
}
.counter.recording {
    display: flex;
    border-style: solid;
    border-width: 0px;
    border-color: gray;
    border-radius: 4px;
    margin: 0px 4px;
    padding: 1px;
    background: var(--color-recording-background);
    color:var(--color-recording-text); 
}
.counter.postpro {
    display: flex;
    border-style: solid;
    border-width: 0px;
    border-color: gray;
    border-radius: 4px;
    margin: 0px 4px;
    padding: 1px;
    background: var(--color-postpro-background);
    color:var(--color-postpro-text); 
}
.counter.qa {
    display: flex;
    border-style: solid;
    border-width: 0px;
    border-color: gray;
    border-radius: 4px;
    margin: 0px 4px;
    padding: 1px;
    background: var(--color-qa-background);
    color:var(--color-qa-text); 
}
.counter.delivery {
    display: flex;
    border-style: solid;
    border-width: 0px;
    border-color: gray;
    border-radius: 4px;
    margin: 0px 4px;
    padding: 1px;
    background: var(--color-delivery-background);
    color:var(--color-delivery-text); 
}
.counter.ai {
    display: flex;
    border-style: solid;
    border-width: 0px;
    border-color: gray;
    border-radius: 4px;
    margin: 0px 4px;
    padding: 1px;
    background: var(--color-ai-background);
    color:var(--color-ai-text); 
}

.counterHeader {
    font-size: 80%;
    margin: 4px;
    align-self: center;
}
.counterLineTitle {
    margin: 2px;
    padding: 0px 6px;
    border-radius: 3px;
    border-width: 0px;
    border-style: solid;
    background: #ffffffd5;
    border-color: gray;
    color: rgb(100, 100, 100);
    font-size: 80%;
    align-self: center;
    text-wrap: nowrap;
}
.counterValues {
    margin: 0px 2px;
    color:rgb(75, 75, 75);
    align-self: center;
    font-size: initial;
    font-weight: bold;
}

.pronunciationsDiv {
    margin: 0px;
    padding: 0px;
}
.pronunciations {
    background: rgba(255, 255, 255, 0.5);
    margin: 1px;
    padding: 2px 4px;
    border: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgb(0, 0, 0, 0.2);
}
.pronunciationsHighlight {
    color: rgb(220, 0, 0);
    /*text-decoration: underline;*/
    cursor: context-menu;
}

.alternativesTemplateDiv {
    margin: 0px;
    padding: 0px;
}
.alternativesTemplate {
    background: rgba(255, 255, 255, 0.5);
    margin: 1px;
    padding: 2px 4px;
    border: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgb(0, 0, 0, 0.2);
    text-wrap: nowrap; 
    text-overflow: ellipsis;
    font-weight: normal;
}
.alternativesTemplateExpanded {
    text-wrap: wrap; 
    border-color: rgba(199, 0, 0, 0.705);
    border-width: 2px;
    font-weight: bold;
}

.spellCheckHighlight {
    text-decoration-style: wavy;
    text-decoration-color: red;
    text-decoration-thickness: 1px;
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
    cursor: pointer;
}

.doublespaces {
    display: inline-flex;
    gap: 1px;
    cursor: pointer;
}

.doublespaceshighlight {
    text-decoration: underline;
    text-decoration-color: #F00;
    text-decoration-thickness: 2px;
}

.nonbreakspace {
    border: 1px solid rgb(235, 190, 44);
}


.taskNotesDiv{
    margin: 2px;
    padding: 0px;
}

.bdNotesDiv{
    margin: -2px;
    padding: 0px;
}

div.bdnotesicon {
    height: auto;
    width: 15px;
    border-radius: 3px;
    margin-right: 4px;
}

.bdNotes {
    display: flex;
    margin: 7px 0px;
    padding: 2px 2px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgb(0, 0, 0, 0.2);
    background-color: white;
}

.bdNotes.actor{
    border-color: #1400ff57;
    background: #0029ff0f;
    color: #0129ab;
}

.bdNotes.global{
    border-color: #ff000052;
    background: #ff000014;
    color: #670000;
}

.bdNotes.local{
    border-color: #0fa70f80;
    background: #3dff000a;
    color: #014f0c;
}

.contextNote.bdnotescell {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.bdnotescell i{
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}


.taskNotes {
    display: flex;
    margin: 1px 0px;
    padding: 2px 2px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgb(0, 0, 0, 0.2);
    background-color: white;
}
div.tasknotesmark {
    height: auto;
    width: 6px;
    border-radius: 3px;
    margin-right: 4px;
}

div.tasknotesmark {
    height: auto;
    width: 6px;
    border-radius: 3px;
    margin-right: 4px;
}


div.tasknotesmark.pre{
    background: var(--color-precheck-background);
}
div.tasknotesmark.adapt{
    background: var(--color-adaptation-background);
}
div.tasknotesmark.recorded{
    background: var(--color-recording-background);
}
div.tasknotesmark.post{
    background: var(--color-postpro-background);
}
div.tasknotesmark.qa{
    background: var(--color-qa-background);
}
div.tasknotesmark.del{
    background: var(--color-delivery-background);
}
div.tasknotesmark.ai{
    background: var(--color-ai-background);
}

.scriptconfimationmenu {
    position: absolute;
    font-size: small;
    width: auto;
    z-index: 3;
    border-style: solid;
    border-color: #b3b3b3;
    background: white;
    border-width: 1px;
    border-radius: 4px;
    padding: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.scriptconfimationmenu.hidden {
    display: none;
}

.scriptconfimationmenu__button {
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    font-weight: 500;
}
.scriptconfimationmenu__button.completeprecheck{
    border-color: var(--color-precheck-background);
    background: var(--color-precheck-background);
    color:var(--color-precheck-text);
} 
.scriptconfimationmenu__button.uncompleteprecheck{
    border-color: var(--color-precheck-background);
    background: whitesmoke;
    color:#8b8b8b;   
} 
.scriptconfimationmenu__button.completeadaptation{
    border-color: var(--color-adaptation-background);
    background: var(--color-adaptation-background);
    color:var(--color-adaptation-text);
} 
.scriptconfimationmenu__button.uncompleteadaptation{
    border-color: var(--color-adaptation-background);
    background: whitesmoke;
    color:var(--color-adaptation-background);   
} 
.scriptconfimationmenu__button.completerecording{
    border-color: var(--color-recording-background);
    background: var(--color-recording-background);
    color:var(--color-recording-text);
} 
.scriptconfimationmenu__button.uncompleterecording{
    border-color: var(--color-recording-background);
    background: whitesmoke;
    color:var(--color-recording-background);   
} 
.scriptconfimationmenu__button.completepostpro{
    border-color: var(--color-postpro-background);
    background: var(--color-postpro-background);
    color:var(--color-postpro-text);
} 
.scriptconfimationmenu__button.uncompletepostpro{
    border-color: var(--color-postpro-background);
    background: whitesmoke;
    color:#b39600;   
} 
.scriptconfimationmenu__button.completeqa{
    border-color: var(--color-qa-background);
    background: var(--color-qa-background);
    color:var(--color-qa-text);
} 
.scriptconfimationmenu__button.uncompleteqa{
    border-color: var(--color-qa-background);
    background: whitesmoke;
    color:var(--color-qa-background);   
} 
.scriptconfimationmenu__button.completedelivery{
    border-color: var(--color-delivery-background);
    background: var(--color-delivery-background);
    color:var(--color-delivery-text);
} 
.scriptconfimationmenu__button.uncompletedelivery{
    border-color: var(--color-delivery-background);
    background: whitesmoke;
    color:var(--color-delivery-background);   
} 
.scriptconfimationmenu__button.completeai{
    border-color: var(--color-ai-background);
    background: var(--color-ai-background);
    color:var(--color-ai-text);
} 
.scriptconfimationmenu__button.uncompleteai{
    border-color: var(--color-ai-background);
    background: whitesmoke;
    color:var(--color-ai-background);   
} 

.qapriority__buttons {
    display: flex;
    flex: 1;
    height: 15px;
    margin: 1px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    border-style: solid;
    border-width: 1px;
    font-size: 0.7em;
    background: white;
    cursor: pointer;
}
option:hover { 
    background: #ffffff62 !important;
}
.filterclearall:hover {
    background: #eee;
}
/* (Dani): End of styles added by Dani */


tr.e-row.lineRecorded td{
    background: #ff000042;
}

#audio{
    position: absolute;
    right: 34px;
    top: 129px;
    border-radius: 0px;
}

.e-unboundcelldiv button.playB,
.e-unboundcelldiv button.temp{
    position: relative;
    display: inline;
    margin: 4px;
    font-size: 16px;
    padding: 4px 4px;
}

.e-unboundcelldiv button.playB:hover,
.e-unboundcelldiv button.temp:hover{
    color: blue
}


/* BD ASSIGN */
.e-row.ActorParent td{
    background: #f5f4f4
}
.e-row.ActorParent td.e-rowcell.highlight{
    font-weight: bold;
}
.e-row.CharacterChild td{
    background: #fff
}

.e-lib.e-btn.e-control.actor_Add.disabled{
    display:none;
}

/* TRELLO */


#kanban .e-header-row th:nth-child(1){
    background: #da0000b2
}
#kanban .e-header-row th:nth-child(2){
    background: #fff30db2
}
#kanban .e-header-row th:nth-child(3){
    background: #0070c0b2
}
#kanban .e-header-row th:nth-child(4){
    background: #20831bb2
}
#kanban .e-header-row th:nth-child(5){
    background: #cecece
}

#kanban .e-content-cells:nth-child(1){
    background: #da000010;
    height: 600px;
}
#kanban .e-content-cells:nth-child(2){
    background: #fff30d10
}
#kanban .e-content-cells:nth-child(3){
    background: #0070c010
}
#kanban .e-content-cells:nth-child(4){
    background: #20831b10
}

#kanban .e-header-row th:nth-child(1) .e-header-text,
#kanban .e-header-row th:nth-child(1) .e-item-count,
#kanban .e-header-row th:nth-child(3) .e-header-text,
#kanban .e-header-row th:nth-child(3) .e-item-count,
#kanban .e-header-row th:nth-child(4) .e-header-text,
#kanban .e-header-row th:nth-child(4) .e-item-count{
    color: #fff;
}

.e-kanban-dialog.e-dialog-delete { 
    display: none; 
} 

#kanban_dialog_wrapper .e-dlg-header-content,
#kanban_dialog_wrapper .e-dlg-content,
#kanban_dialog_wrapper .e-footer-content{
    width: 530px;
}

/* rewrite textarea */
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table .e-field{
    width: 420px;
    height: 320px;
}

/* player container */


.loadingScript{
    color: rgb(1, 88, 122);
    font-size: 20px;
    height: 200px;
}



/* GPT */

.gptContainer{
    position: relative;
    width: 50%;
    margin: 0 auto;
}

.gptContainer div.mine{
    position: relative;
    padding: 10px;
    background:#ededed;
    border-bottom: 1px solid #c3c3c3;
}

.gptContainer div.server{
    position: relative;
    padding: 10px;
    background:#e1e5f9;
    border-bottom: 1px solid #c3c3c3;
    white-space: pre-wrap;
}

#formchatgpt{
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    background: #e4feff;
    border-bottom: 1px solid #c3c3c3;
}

#formchatgpt input{
    width: 90%;
    padding: 5px;
    margin: 5px 0px;
}

#formchatgpt button{
    width: 10%;
    padding: 5px;
    margin: 5px 0px;
}


/* breadcrumb */

.breadcrumb .breadcrumb-item .multiple{
    color: #ff8d00
}


/* Project Tabs */
#borderedTab{
    font-size: 0.8em;
}

#borderedTab button i{
    position: relative;
    top: 1px;
}

#borderedTab a.nav-item button.active{
    border-right-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 1px;
    border-color: #0d6efd #dee2e6 #fff;
    border-top-width: 5px;
    padding: 5px 16px 9px;
}

/* modals */

#basicModal,
#AudioMateMenu{
    display: block;
    background: #0000004a;
}

.e-headercontent .e-headercell.recordings{
    background: #F00
}

/* Upload source files */

.card.uploadmodule.source tr.e-columnheader th,
#downloadModule.card.source tr.e-columnheader th{
    background: #198754;
    color: white;
}

.card.uploadmodule.target tr.e-columnheader th,
#downloadModule.card.target tr.e-columnheader th{
    background: #0d6efd;
    color: white;
}

.audiomate-upload-zone{
    position: relative;
    max-width: 590px;
    height: 150px;
    border: 4px dashed rgb(193, 193, 193);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: gray;
    background-color: white;
    cursor: pointer;
}

#audiomate-upload select,
#audiomate-upload input{
    height: 35px;
    min-height: 35px;
    width: 300px;
}

.uploaderTable .e-grid .e-gridheader .e-stackedheadercelldiv,
.uploaderTable .e-grid .e-gridheader .e-sortfilter .e-headercelldiv{
    text-overflow: clip;
}

select.selectfieldTarget{
    height: 32px;
    min-height: 31px;
    width: 400px;
    line-height: 0.85;
}

.progress-bar.no-transition{
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
}

table#audiomate-upload-list{
    font-size: 0.8em;    
}

table#audiomate-upload-list tr.Found td{
    background: #0dff5633;
}

table#audiomate-upload-list tr.Missing td{
    background: #ff0d0d2f;
}

table#audiomate-upload-list tr.Extra td{
    background: #0d11ff2f;
}

#zenDeskHelp{
    background-color: #ffa402;
    position: absolute;
    top: 15px;
    right: 1000px;
    z-index: 1053;
    border-radius: 5px;
    opacity: 1
}

.zenDeskHelpReact{
    background-color: #FF851B;
    z-index: 1054;
    border-radius: 30px;
    padding: 8px 16px;
    font-weight: bold;
    border: 0px;
    width: 98px;
}

.zenDeskHelpReact:hover,
.zenDeskHelpReact i:hover{
    background-color: #ff9940;
    color: white;
}

.zenDeskHelpReact:active{
    background-color: #b65e12;
    color: #000;
}

.showhidetargetchanges{
    background: var(--script-highlightchanges-button-background);
    color: var(--script-highlightchanges-button-text);
}

.showhidepronunciationsbutton{
    background: var(--showpronunciations-button-background);
    color: var(--showpronunciations-button-text);
}

.showpronunciations.panelvisible {
    width: 500px; 
}

.showpronunciations {
    display: grid; 
    overflow: auto;
    width: 0px;
    border-width: 1px; 
    border-color: #b9b9b9;
    border-style: solid;
    align-content: baseline;
    transition: width 0.4s;
}

.showpronunciations.hidden {
    display: none;
}

.pronunciations_confirm_msg {
    display: none;
    margin: 5px;
    padding: 3px;
    border-width: 1px;
    border-color: #f5c2c7;
    border-radius: 4px;
    border-style: solid;
    text-align: center;
    background: #f8d7da;
    color: #842029; 
    justify-content: end;
    align-items: baseline;
}
.pronunciations_confirm_msg.visible {
    display: flex;
}

.pronunciations_new_popup {
    padding: 4px;
    width: 300px;
    position: absolute;
    z-index: 2;
    background: white;
    border-color: rgb(190, 190, 190);
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}
.pronunciations_new_popup.hidden {
    display: none;
}
.pronunciations_inputs {
    padding: 4px;
    margin-bottom: 4px;
    font-size: 14px;
}
.pronunciations_inputs.error {
    background-color: rgba(255, 0, 0, 0.2);
}

.pronunciations_new_term_msg  {
    padding: 4px;
    margin-right: 4px;
    justify-content: center;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    background: #f8d7da;
    border-color: #f5c2c7;
    color: #842029;
    font-size: 14px; 
}
.pronunciations_new_term_msg.hidden {
    display: none;
}

.showhideadaptmarks{
    background: var(--ssmarks-button-background);
    color: var(--ssmarks-button-text);
}

.ssmarks{
    display: var(--ssmarks-visibility);
} 

/* COLOR CONSTRAINTS */

#scriptTable .e-rowcell.LS,
#scriptTable .e-grid td.e-active.LS{
    background: var(--color-constraints-LS-background);
    color: var(--color-constraints-LS-text)
}

#scriptTable td.e-rowcell.SS,
#scriptTable .e-grid td.e-active.SS{
    background: var(--color-constraints-SS-background);
    color: var(--color-constraints-SS-text)
}

#scriptTable .e-rowcell.STC,
#scriptTable .e-grid td.e-active.STC{
    background: var(--color-constraints-STC-background);
    color: var(--color-constraints-STC-text)
}

#scriptTable .e-rowcell.TC5,
#scriptTable .e-grid td.e-active.TC5{
    background: var(--color-constraints-TC5-background);
    color: var(--color-constraints-TC5-text)
}

#scriptTable .e-rowcell.TC10,
#scriptTable .e-grid td.e-active.TC10{
    background: var(--color-constraints-TC10-background);
    color: var(--color-constraints-TC10-text)
}

#scriptTable .e-rowcell.TC15,
#scriptTable .e-grid td.e-active.TC15{
    background: var(--color-constraints-TC15-background);
    color: var(--color-constraints-TC15-text)
}

#scriptTable .e-rowcell.TC20,
#scriptTable .e-grid td.e-active.TC20{
    background: var(--color-constraints-TC20-background);
    color: var(--color-constraints-TC20-text)
}

#scriptTable .e-rowcell.TC25,
#scriptTable .e-grid td.e-active.TC25{
    background: var(--color-constraints-TC25-background);
    color: var(--color-constraints-TC25-text)
}

#scriptTable .e-rowcell.TC30,
#scriptTable .e-grid td.e-active.TC30{
    background: var(--color-constraints-TC30-background);
    color: var(--color-constraints-TC30-text)
}

#scriptTable .e-rowcell.TC50,
#scriptTable .e-grid td.e-active.TC50{
    background: var(--color-constraints-TC50-background);
    color: var(--color-constraints-TC50-text)
}

#scriptTable .e-rowcell.NLT,
#scriptTable .e-grid td.e-active.NLT{
    background: var(--color-constraints-NLT-background);
    color: var(--color-constraints-NLT-text)
}

#scriptTable .e-rowcell.VO,
#scriptTable .e-grid td.e-active.VO{
    background: var(--color-constraints-VO-background);
    color: var(--color-constraints-VO-text)
}


/* adaptation marks management */
#adaptationMarks table .e-rowcell {
    white-space: normal !important;
}


/****************** Tutorial page */

.card.arrowpointer{
    cursor: context-menu;
}

.descriptionBox{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 48px;

}

/* donwload into script */

.selection-download{
    cursor: pointer;
}

.selection-download:not(.disabled):hover{
    background: #0927c01a;
    box-shadow: 0px 0px 5px 0px #0000004a;
}

.selection-download.disabled{
    cursor: not-allowed;
    background: #ebebeb;
    color: #888;
}

.selection-download.disabled h5{
    color: #888;
}

#videoWatemark::before {
    content: var(--watermark-text);
    color: var(--watermark-text-color);
    opacity: var(--watermark-text-opacity);
    font-size: var(--watermark-text-size);
    position: absolute;
    transform: translate(0%, 0%) rotate(-45deg);
    z-index: 1; 
    width: 1000px; 
    height: 1000px; 
}

/*.sidebar{
    height: 100%;
    overflow: auto;
    position: absolute;
    padding-right: 20px; /* Aggiunge padding per il contenuto 
    margin-right: -20px;
}

.sidebar::-webkit-scrollbar {
    width: 0px;
}

 Per nascondere la barra di scorrimento su Firefox 
.sidebar{
    scrollbar-width: none;
}*/

/* temporary qa notes */

.extracomponents{
    position: relative;
    bottom: 2px;
    display: block;
    margin-top: 5px;
}

.badge.am-bugs-badge{
    font-size: 0.9em;
    padding: 4px;
    border-radius: 4px;
    margin: 2px 0px;
    cursor: default;
}

.badge.am-bugs-badge.bg-warning{
    color: black;
}

.am-bugs-badge-icon{
    margin-right: 4px;
}

.extracomponents button{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 10px;
    padding: 0px;
    margin: 1px;
}

.extracomponents-large button{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 20px;
    padding: 0px;
    margin: 2px;
}

.qaTags-preview{
    padding: 12px;
    position: relative;
}

#scriptTable tr.e-row td.foundMatchSearch,
#scriptTable .e-grid.e-default.e-bothlines .e-rowcell.foundMatchSearch{
    background: #0008ff26;
    border: 3px dashed #ff0000;
}

@keyframes flashingCellUndoRedoCell {
    0%, 100% { background-color: transparent; }
    50% { background-color: yellow; }
}

#scriptTable tr.e-row td.highlightUndoRedo{
    animation: flashingCellUndoRedoCell 0.5s ease-in-out infinite;
    border: 3px dashed #ff0000;
}

/* download buttons */

.download-requests{
    min-width: 90px
}

#listProjectTable .e-row td.e-rowcell{
    height: 15px !important;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.headerButton{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 20px;
    border-radius: 4px;
    padding: 2px;
    width: 20px;
    font-size: 13px;;
}
.headerButton:hover{
    background: #ffffff30;
}

#amContextMenu {
    position: absolute;
    list-style: none;
    padding: 0;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 10; 
    border-radius: 4px; 
    overflow: hidden; 
    user-select: none;
    white-space: nowrap;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
    font-size: 14px;
    font-weight: normal;
    width: 150px;
}
#amContextMenu .contextMenu-ul-li {
    padding: 4px 10px;
}
#amContextMenu .contextMenu-ul-li:hover {
    background: #f0f0f0;
}

#customAudioPlayer.container {
    height: 20px;
    width: 100%;
    margin: 0 -5px;
    padding: 2px;
    position: absolute;
    top: 0px;
    display: flex;
    font-size: 0.88em;
}
#customAudioPlayer.container.disabled {
    opacity: 0.7;
    pointer-events: none;
}
#customAudioPlayer .player {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 6px;
    border-radius: 10px;
    overflow: hidden;
    background: #eee;
    gap: 8px;
    border: 1px solid #ddd;
    color: #555;
}
#customAudioPlayer .playButton {
    cursor: pointer;
}
#customAudioPlayer .playButton:hover {
    color: #0283c3;
}
#customAudioPlayer .playButton.loading {
    animation: spin 0.6s linear infinite;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #0283c3;
    border-right-color: transparent;
}
#customAudioPlayer .playing {
    color: #00b300;
}
#customAudioPlayer .trackerContainer {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    cursor: pointer;
}
#customAudioPlayer .tracker {
    display: flex;
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: content-box;
    overflow: hidden;
    background: #fff;
    height: 4px;
    pointer-events: none;
}
#customAudioPlayer .trackerBar {
    background: #555;
    pointer-events: none;
}
#customAudioPlayer .counter {
    pointer-events: none;
}

#xlocgrid th.e-headercell{
    background: #222d65;
    color: #FFF;
}
#xlocgrid.e-grid .e-icon-filter {
    color: rgba(255, 255, 255, 0.514);
}
#xlocgrid.e-grid .e-icon-filter.e-filtered::before {
    color: rgb(223, 0, 0);
}

#xlocgrid.e-grid td.e-cellselectionbackground {
    outline: 2px solid #003c96;
    outline-offset: -2px;
    background: transparent;
}
#xlocgrid td {
    padding: 0px 5px;
    position: relative;
}
.xlocMapButton {
    position: relative;
    font-size: 21px;
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: auto 2px auto -4px;
    color: #ffffff83;
}
.xlocMapButton.mapped {
    color: #ffffff;
}
.xlocMapButton:hover {
    background: #70a9ff71;
}
.xloc-mapping-ul {
    position: fixed;
    z-index: 10;
    background: #fff;
    border: 1px solid #00000020;
    box-shadow: 0px 7px 15px 8px #00000020;
    color: #000;
    border-radius: 0;
    list-style-type: none;
    padding: 6px;
    font-size: 13px;
    font-weight: normal;
    min-width: 150px;
}
.xloc-mapping-ul-li {
    margin: 0;
    color: #555;
    padding: 0 4px;
}
.xloc-mapping-ul-li:hover {
    background: #e9f3ff;
}
.xloc-mapping-ul-li.selected {
    font-weight: bold;
}
/* customized scrollbar */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

/* scrollbar color color */
::-webkit-scrollbar-track {
    background: #f0f0f0; 
    border-radius: 10px;
}

/* Color and style of the scrollbar "thumb". */
::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px; 
    border: 2px solid #f0f0f0;
}

/* Color of the "thumb" when hovered over with the mouse */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Color of the "thumb" when active (while clicking is held) */
::-webkit-scrollbar-thumb:active {
    background-color: #333; 
}

