.videoplayerContainer #draggableVideo{
    cursor: grab;
}

.videoplayerContainer.portal #draggableVideo{
    cursor: default;
}

.detatableIcon{
    right: 29px;
    font-size: 12px;
    position: absolute;
}

.videoplayerContainer.portal .detatableIcon{
    display: none;
}

.videoplayerContainer.portal .crossClose{
    display: none;
}

#draggableVideo .card-title{
    padding: 6px 2px 0px 0px;
}

#draggableVideo .closeUI{
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 27px;
}

.card.videoplayer{
    padding: 3px 12px 7px 12px;
    border-radius: 5px;
    margin: 0px;
}

.videoplayerContainer.portal .card.videoplayer{
    /*height: 100%;*/
    border-radius: 0px;
}

.controls{
    position: relative;
    width: 100%;
    margin: 0px 0px 4px 0px;
    text-align: center;
    font-size: 3px;
}

.playersControls button{
    margin-top: 2px;
    padding: 0px;
    line-height: 18px;
    padding: 2px 0px 4px;
    border-radius: 3px;
    width: 60px; 
}

.playersControls button svg{
    font-size: 15px;
}

#videoWatemark{
    position: absolute;
    top: 7px;
    left: 7px;
    width: 98%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
}







#audioPlayerPlace{
    position: relative;
    background: #45454521;
    padding: 4px;
    color: #333;
    border-radius: 4px;
    margin-bottom: 3px;
    font-size: 12px;
}

#videowaveform{
    border: 1px solid #ccc;
    height: 60px
}

#videoPlayerPlace{
    position: relative;
    background: #45454521;
    padding: 7px 7px 2px 7px;
    color: #FFF;
    border-radius: 4px;
    margin-bottom: 3px
}

#coverLoading{
    position: absolute;
    width: 99%;
    height: 90%;
    background: #d7d7d7a3;
    z-index: 1000;
}

#coverLoading div{
    padding: 20px;
    padding-left: 45%
}

.moveIconResize{
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 10px;
    opacity: 0.5;
    cursor: nwse-resize;
}

.videoplayerContainer.portal .moveIconResize{
    display: none;
}

.moveIconResize:hover{
    opacity: 1;  
}

.moveIconResize .icon {
    box-sizing: border-box;
    display: inline-block;
    font-size: inherit;
    font-style: normal;
    position: relative;
    text-indent: -9999px;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    line-height: 100%;
  }
  
.moveIconResize .icon::before,
.moveIconResize .icon::after {
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
  

.moveIconResize .icon-link::before {
    border: .1rem solid currentColor;
    border-right: 0;
    border-top: 0;
    border-left: 0;
    height: 1.4em;
    transform: translate(-84%, -84%) rotate(-45deg); 
    width: 1.4em;
}
.moveIconResize .icon-link::after {
    border: .1rem solid currentColor;
    border-right: 0;
    border-top: 0;
    border-left: 0;
    height: .7em;
    transform: translate(-50%, -50%) rotate(-45deg); 
    width: .7em;
}
  