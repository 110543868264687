
/* buttons to add tags to input text */
.manageTarget .buttontags button{
    margin: 0px 3px;
    font-Size: 12px;
    padding: 0px 8px;
    border-radius: 8px
}

/* padding of the grid */
.manageTarget td.e-rowcell{
    padding: 4px 4px;
}

.manageTarget .e-grid .e-row .e-input-group .e-input.e-field{
    top: 4px;
    padding: 0px
}

.centered-badge {
    position: absolute;
    left: 50%;
}

.manageTarget .e-grid .e-row .e-selectionbackground{
    background-color: rgba(24, 41, 136, 0.096);
}

/* LUFS modal */

.mo_dal-overlay-lufs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content-lufs {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 500px;
    z-index: 1055 !important;
    border-radius: 9px;
  }

.e-slider-container{
    position: relative;
    top: 15px;
}

.sliderPlaces{
    position: relative;
    height: 100px
}

.sliderPlacesTP{
    position: relative;
    height: 90px
}

#specs-data-form{
    font-size: 12px;
    border-radius: 5px;
    padding: 2px;
}

.editableValue{
    display: inline-block;
    text-align: center;
    background: #efefe5;
    border: 1px solid #e9e6e6;
    border-radius: 3px;
    min-width: 20px;
    cursor: pointer;
}

.handValues{
    width: 50px;
    text-align: center;
    padding: 0px;
    height: 19px;
    border: 1px solid #F00;
    background: rgb(236, 202, 202);
}